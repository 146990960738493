



















import { Component, Vue, Watch } from 'vue-property-decorator'
import PageLayout from '@/components/common/page-layout/PageLayout.vue'
import ContentArea from '@/components/common/ContentArea.vue'
import Title from '@/components/common/page-layout/Title.vue'
import ProgressLine from '@/components/ProgressLine.vue'
import { Route } from 'vue-router'
import { LocaleMessage } from 'vue-i18n'

@Component({
  components: {
    PageLayout,
    ContentArea,
    Title,
    ProgressLine
  }
})
export default class Demographic extends Vue {
  private advancement = 0
  private hideStepCount = true
  private pathTitle: LocaleMessage | undefined

  private mounted (): void {
    if (window.innerWidth < 768) {
      this.$root.$on('mobile-go-back', () => {
        this.goBack()
      })
    }
  }

  private goBack (): void {
    this.$router.go(-1)
  }

  @Watch('$route', { immediate: true, deep: true })
  onRouteChange (newRoute: Route): void {
    this.advancement = newRoute?.meta?.advancement
    this.hideStepCount = newRoute?.meta?.hideStepCount || false

    this.pathTitle = this.$t('demographic.title')
    switch (this.$route.name) {
      case 'demographic 4':
        this.pathTitle = this.$t('demographic.step_4.title')
        break
      case 'demographic 3':
        this.pathTitle = this.$t('demographic.step_3.title')
        break
      case 'demographic 2':
        this.pathTitle = this.$t('demographic.step_2.title')
        break
      default:
        this.pathTitle = this.$t('demographic.title')
        break
    }
  }

  private destroyed (): void {
    if (window.innerWidth < 768) this.$root.$off('mobile-go-back')
  }
}
